"use strict";

require("core-js/modules/es.symbol.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.json.stringify.js");
var _utilities = require("../helpers/utilities");
var _fetch = require("../helpers/fetch");
var _excluded = ["date_created", "date_last_seen", "date_last_heard_from", "date_last_session_start", "parent", "cookies", "seen_count", "session_count", "conv_count", "custom_data", "last_ip", "integrations", "emails", "address", "geoip", "social_identifiers", "avatar"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function filterBlacklisted(data) {
  var date_created = data.date_created,
    date_last_seen = data.date_last_seen,
    date_last_heard_from = data.date_last_heard_from,
    date_last_session_start = data.date_last_session_start,
    parent = data.parent,
    cookies = data.cookies,
    seen_count = data.seen_count,
    session_count = data.session_count,
    conv_count = data.conv_count,
    custom_data = data.custom_data,
    last_ip = data.last_ip,
    integrations = data.integrations,
    emails = data.emails,
    address = data.address,
    geoip = data.geoip,
    social_identifiers = data.social_identifiers,
    avatar = data.avatar,
    valid = _objectWithoutProperties(data, _excluded);
  return valid;
}
function fetchAnalyticsUpdate(state, fId, data) {
  var filteredData = filterBlacklisted(data);
  var fetchData = {
    f_id: fId,
    data: filteredData
  };
  var fetchOptions = {
    body: JSON.stringify(fetchData),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST'
  };
  var endpoint = (0, _utilities.getPoloUrl)("/analytics/update/".concat(state.accountId), state.devMode);
  return (0, _fetch.fetch)(endpoint, fetchOptions);
}
function update(data) {
  var state = this.boomerang.state;
  if (!state.accountId) {
    (0, _utilities.log)('error', 'Cannot update before `fly` or without an account id.');
    return false;
  }
  var requestPromise;
  if ((0, _utilities.isPromise)(state.fId)) {
    requestPromise = state.fId.then(function (fId) {
      return fetchAnalyticsUpdate(state, fId, data);
    });
  } else {
    requestPromise = fetchAnalyticsUpdate(state, state.fId, data);
  }
  return requestPromise.then(function (response) {
    try {
      return response.json();
    } catch (error) {
      (0, _utilities.log)('error', error);
      return {};
    }
  }).then(function (responseData) {
    (0, _utilities.log)("Update response: ".concat(JSON.stringify(responseData)));
  })["catch"](function (reason) {
    (0, _utilities.log)('error', "Update request failed: ".concat(reason, "."));
  });
}
var _default = exports["default"] = update;