"use strict";

require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
require("core-js/modules/es.json.stringify.js");
var _utilities = require("./helpers/utilities");
var _api = require("./api");
function invoke() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  if (!args.length) return;
  (0, _utilities.log)("Invoking method with arguments ".concat(JSON.stringify(args)));
  var methodName = args.shift();
  var methodArgs = args;
  if (args.length === 1 && (0, _utilities.isArray)(args[0])) {
    methodArgs = args[0];
  }
  switch (methodName) {
    case 'fly':
    case 'init':
      // Initialization activity
      _api.fly.apply(this, methodArgs);
      break;
    case 'update':
    case 'attribute':
      // Use appropriate polo method to update permanent attributes on Person
      _api.update.apply(this, methodArgs);
      break;
    case 'sprinkle':
    case 'activity':
      // Manually triggered Breadcrumb creation
      _api.sprinkle.apply(this, methodArgs);
      break;
    case 'match':
      _api.match.apply(this, methodArgs);
      break;
    case 'integrate':
      _api.integrate.apply(this, methodArgs);
      break;
    case 'convert':
      _api.convert.apply(this, methodArgs);
      break;
    default:
      // No such method found
      (0, _utilities.log)('error', "No such method found for method name \"".concat(methodName, "\""));
      break;
  }
}
var _default = exports["default"] = invoke;